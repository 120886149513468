import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { basic } from "../../themes/basic";

const PageHeading = ({ title }) => {
  return (
    <AppBar
      position="static"
      style={{
        background: "inherit",
        boxShadow: "inherit",
      }}
    >
      <Toolbar style={{ padding:"0px"}}>
        <Typography variant="h6" component="div" style={{ fontSize:basic.headingFontSize,fontWeight:"bold", fontFamily:basic.fontFamily, color: "#000000", textAlign:"center", width:"100%" }}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeading;
