import React, { useState, useEffect, Fragment  } from 'react';
import ArrowBackIos from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import CustomButton2 from "./button_one"; 
import { basic } from "../../../themes/basic";

const BackButton = ({ backToPage, aButtonText }) => {
  const navigate = useNavigate(); 

  const handleBackClick = () => {
    if (backToPage) {
      navigate(backToPage); 
    } else {
      navigate(-1); 
    }
  };

  return (
    <CustomButton2    
    onClick={handleBackClick}
    aria-label={aButtonText}
    style={{minWidth: "83px"}}
    >
      <ArrowBackIos style={{ marginTop:-1}} fontSize={basic.headingFontSize} />
      <div style={{ marginLeft: 5 }}>{aButtonText}</div>
    </CustomButton2>
    
  );
};

export default BackButton;
