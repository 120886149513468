import { TextField } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import useGlobalData from '../../../Zustand/GlobalData';
import { useNavigate } from 'react-router-dom';

export default function PasswordModal({ actionType, show, onClose }) {
    const [inputField, setInputField] = useState({});
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (show) {
            // Reset the fields when the modal is shown
            setInputField({});
            setMessage("");
            setErrorMessage("");
        }
    }, [show]);

    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value
        });
        setErrorMessage("");
        setMessage("");
    };

    const handleClose = () => {
        onClose(); // Use the callback from props to close the modal
        setErrorMessage("");
        setInputField({});
        setMessage("");
    };

    const sendForgotPasswordLink = (e) => {
        e.preventDefault();

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputField.userEmail)) {
            setErrorMessage("Invalid email format");
            return;
        }

        const payload = {
            userEmail: inputField.userEmail,
        };

        axios.post("/admin/sendForgotPwdLink", payload)
            .then((res) => {
                setMessage(res.data.message);
                setTimeout(() => handleClose(), 2000);
            })
            .catch(() => {
                setErrorMessage("This Email is not registered");
            });
    };

    const onChangePasswordClick = () => {
        const { oldPassword, newPassword, confirmPassword } = inputField;

        if (!oldPassword || !oldPassword.trim()) {
            setErrorMessage("Please enter Current Password");
        } else if (!newPassword || !newPassword.trim()) {
            setErrorMessage("Please enter New Password");
        } else if (!confirmPassword || !confirmPassword.trim()) {
            setErrorMessage("Please enter Confirm Password");
        } else if (newPassword !== confirmPassword) {
            setErrorMessage("New Password and Confirm Password must match");
        } else if (oldPassword === newPassword) {
            setErrorMessage("New Password cannot be the same as Current Password");
        } else {
            const payload = {
                oldPassword,
                newPassword,
            };

            axios.post("/admin/updateNewPassword", payload)
                .then((res) => {
                    setMessage(res.data.message);
                    if (res.data.isPasswordUpdated) {
                        setTimeout(() => {
                            localStorage.clear();
                            navigate("/");
                            handleClose();
                        }, 2000);
                    }
                })
                .catch(() => {
                    setErrorMessage("Error, please try again.");
                });
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{actionType === "changePassword" ? "Change Password" : "Forgot Password"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {actionType === "changePassword" ? (
                    <>
                        <TextField
                            className="mt-3"
                            size="medium"
                            name="oldPassword"
                            label="CURRENT PASSWORD"
                            variant="outlined"
                            fullWidth
                            type="password"
                            onChange={onChangeTxt}
                        />
                        <TextField
                            className="mt-3"
                            size="medium"
                            name="newPassword"
                            label="NEW PASSWORD"
                            variant="outlined"
                            fullWidth
                            type="password"
                            onChange={onChangeTxt}
                        />
                        <TextField
                            className="mt-3"
                            size="medium"
                            name="confirmPassword"
                            label="CONFIRM NEW PASSWORD"
                            variant="outlined"
                            fullWidth
                            type="password"
                            onChange={onChangeTxt}
                        />
                    </>
                ) : (
                    <TextField
                        className="mt-3"
                        size="medium"
                        name="userEmail"
                        label="ENTER EMAIL ADDRESS"
                        variant="outlined"
                        fullWidth
                        onChange={onChangeTxt}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="secondary-btn" onClick={handleClose}>
                    Close
                </button>
                <button
                    className="btn-latest"
                    onClick={actionType === "changePassword" ? onChangePasswordClick : sendForgotPasswordLink}
                >
                    {actionType === "changePassword" ? "Save" : "Send Link"}
                </button>
            </Modal.Footer>
            {errorMessage && <div style={{ textAlign: "center", color: "red" }}>{errorMessage}</div>}
            {message && <div className="text-center p-3">{message}</div>}
        </Modal>
    );
}
