
import { useEffect, useState } from "react";
import React from "react";
import { Autocomplete, Button, Grid, TextField,Box, Typography } from "@mui/material";
// import logoImg from "../Assets/logo.png";
import axios from "axios";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { basic, textFieldAndShrinkStyle } from "../../themes/basic";
import ButtonComponent from "../Admin/common/button_two";
import { NavigateNext as NavigateNextIcon, Login as LoginIcon } from '@mui/icons-material';
function Alexalogin(props) {
    const navigate = useNavigate();
    const [inputField, setInputField] = useState({
        // user_name: "",
        user_email: "",
        otp: "",
        userPassword: "",
        confirmPassword:"",
        // user_type: "DEFAULT",
        schoolCode: "",
        user_reg_num:""
        
    });

    const [isUserValid, SetisUserValid] = useState(true)
    const [codeForOauth, SetCodeForOauth] = useState("")
    const [loading, SetLoading] = useState(false)
    const [errorTxt, SeterrorTxt] = useState("")
    const [schoolLists, SetSchoolLists] = useState([])
    const [searchSchool, SetSearchScool] = useState(false)
    const [filteredSchlLst, SetFilteredSchlLst] = useState([])
    const [slctdSchl, SetSlctSchl] = useState("")
    const [slctSchlCode, SetSlctSchlCode] = useState("")
    const [isUserNew, setIsUserNew] = useState(true)
    const [userLoaded, setUserLoaded] = useState(false)
    const [acontId, setAcntId] = useState("")
    const [userId, setUserId] = useState("")
    const [alreadyQuizUsers, setAlreadyQuizUsers] = useState([])
    const [allQuizDetails, setAllQuizDetails] = useState([])
    const [totalAttempts, setTotalAttempts] = useState(0)
    const [__userRegisterNumber,__setUserRegisterNumber] = useState("");

    const [passwordError, setPasswordError] = useState("");

    const anim = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 1000 },
    });

    //taking inputs from user and assigning to respective variable
    const onChangeTxt = (e) => {
        const { name,value} = e.target;
        if (name == "user_reg_num") {

            console.log(name, value.substring(14))
            let scholCode = value;
            if (value.length >= scholCode.length) {
                let roleNum = value.substring(14);
                SetSlctSchlCode(scholCode + roleNum)
            }
            
        }

        setInputField({
            ...inputField,
            [name]: value,
        });

        if(name === "userPassword" || name === "confirmPassword"){
            if(inputField.userPassword !== value && name === "confirmPassword"){
                setPasswordError("Passwords do not match.");
            }else if(name === "userPassword" && inputField.confirmPassword !=="" && inputField.confirmPassword !== value){
                setPasswordError("Passwords do not match.")
            }else{
                setPasswordError("");
            }
        }
    };


    const onSearchSchool = (e) => {
        SetSlctSchl(e.target.value)
        let key = e.target.value.trim().toLowerCase()
        SetSearchScool(true);
        const filtredLst = schoolLists.filter((dt) => {
            console.log("-------------", dt);
            if (dt.schoolDetails[0]) {
                return dt.schoolDetails[0].schoolName.toLowerCase().includes(key)
            }
        })
        console.log(":ssssssssssssssssss", filtredLst);
        SetFilteredSchlLst(filtredLst)
    }

    useEffect(() => {
        //Loading all shcools for search 
        axios.get("/lmAccounts/getAllSchools")
            .then(res => {
                console.log("All Schools list recieved:", res.data);
                SetSchoolLists(res.data)
            })
            .catch(err => {
                console.log("Error when tried to access schools list:", err);
            })
    }, [])

    // useEffect(() => {
    //     axios.get("/quiz/getAllQuizBatchUserDetails")
    //         .then(res => {
    //             console.log("already batch students", res.data);
    //             setAlreadyQuizUsers(res.data.quizBatchUsers)
    //             setAllQuizDetails(res.data.quizDetails)
    //         })
    //         .catch(err => {
    //             console.log("Error when tried to access schools list:", err);
    //         })
    // }, [])

    //Login button 
    const onSubmitBtn = async (e) => {
        e.preventDefault();
        SetLoading(true);

        let payload = {
            regNum: inputField.user_reg_num,
            otp: inputField.otp,
            userPassword: inputField.userPassword,
            confirmPassword: inputField.confirmPassword,
            id: acontId,
            userId: userId
        };
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const redirect_uri = params.get("redirect_uri");
        const state = params.get("state");
        const code = params.get("code");
        console.log(isUserNew);
        if (isUserNew) {
            console.log("Updating password:  ", payload, "===", userId);

            const _getResponseData = await axios.post("/lmAccountsUser/updatePwd",payload);
            if(_getResponseData.data.status === "success"){
                linkiAlexa();
            }else{
                SeterrorTxt(_getResponseData.data.message);
            }
            //console.log(_getResponseData.data.status);
            /*axios.post('/lmAccountsUser/updatePwd', payload).then((res) => {
                console.log("Not verified", res.data);
                
               if (res.data.isPasswordUpdated) {
                    linkiAlexa()
                }
                else {
                    SeterrorTxt(res.data.message)
                    console.log("Not verified", res.data);
                }
                SetLoading(false) 
            }) */
        }
        else {
            axios.post('/lmAccountsUser/verifyPwd', payload).then((res) => {
                console.log(res.data);
                if (res.data.verified) {
                    linkiAlexa()
                }
                else {
                    SeterrorTxt(res.data.message)
                    console.log("Not verified");
                }
                SetLoading(false)
            })
        }
    };


    const linkiAlexa = (e) => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const redirect_uri = params.get("redirect_uri");
        const state = params.get("state");
        const code = params.get("code");

        axios.post("/alexa/updateAccountLinkStatus").then((res) => {
            console.log(res.data.message);
        });

        window.location.replace(
            `${redirect_uri}?state=${state}&code=${codeForOauth}`
        );
    }



    const onSchoolSelect = (e) => {
        // console.log("Selected school: ", filteredSchlLst[e][0].schoolName);
        console.log("Selected school: ", filteredSchlLst[e]);
        SetSearchScool(false)
        setAcntId(filteredSchlLst[e]._id)
        SetSlctSchl(filteredSchlLst[e].schoolDetails[0].schoolName)
    }


    const onNextBtn = (e) => {
        e.preventDefault()
        let payload = {
            regNum: inputField.user_reg_num,
            schoolCode: slctdSchl,
            _lmAccountId: acontId,
        };
        SeterrorTxt("")
        console.log("Verify user Payload", payload);
        axios.post("/alexa/alexaLoginVerifyUser", payload)
            .then((res) => {
                if (res.data.message != "") {
                    SeterrorTxt(res.data.message)
                    SetCodeForOauth(res.data.code)
                    setUserId(res.data.userId)
                }
                else {
                    setUserLoaded(true)
                    SetCodeForOauth(res.data.code)
                    setUserId(res.data.userId)
                    res.data.isUserAlreadyExist ? setIsUserNew(false) : setIsUserNew(true)
                }
            }).catch((err) => {
                console.log("--------", err);
            })
        console.log("dddddddddddddd", payload);
    }

    return (
        <div>
            <animated.div style={anim} id="REGISTER_FORM">
                {/* {loading ? <Loading /> : null} */}
                <Container style={{ maxWidth: "500px" }} >
                    <Row>
                        <Col xs={12} className={"mt-4"}>
                            <div style={{ textAlign: "center" }}>
                                <label style={{ fontFamily:basic.fontFamily, fontSize:basic.headingFontSize, fontWeight: "bold" }}> Please link your learning matters account (V3) </label>
                            </div>
                        </Col>
                        <Col xs={12} className={"mt-2"}>
                            <TextField required sx={textFieldAndShrinkStyle} size="medium" value={slctdSchl} name="schoolCode" id="outlined-basic" label="School Name" variant="outlined" style={{ width: "100%" }} 
                            onChange={onSearchSchool} onClick={onSearchSchool}
                            error={slctdSchl === ""}
                            helperText={
                                slctdSchl === ""
                                ? "School name is required."
                                 : "" }
                            /> 
                        </Col>
                        <Col xs={12}>
                            {
                                searchSchool ? <Box item xs={12} className="d-flex justify-content-center" sx={{fontFamily:basic.fontFamily}} style={{ position: "relative" }}>
                                    {
                                        filteredSchlLst.length >= 1 ? <Box className="search-list-overlay" sx={{fontFamily:basic.fontFamily}}>
                                            {
                                                filteredSchlLst.map((dt, index) => {
                                                    return <Box xs={12} key={index}>
                                                        <Button key={index} onClick={() => { onSchoolSelect(index); SetSearchScool(false); SetSlctSchlCode((dt.schoolDetails[0].schoolCodeForUsers) + "_") }} sx={{fontFamily:basic.fontFamily}} style={{ textAlign: "start"}}>
                                                            <p style={{fontSize:basic.contentFontSize, marginBottom:"5px"}}> <b>{dt.schoolDetails[0].schoolName} </b><span style={{ color: "#353535", textTransform: "initial" }}>{dt.schoolDetails[0].schoolAddress}</span></p>
                                                        </Button>
                                                    </Box>
                                                })
                                            }
                                        </Box> : <Typography variant="h6" component="div" style={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily, color: "#000000", textAlign:"center", width:"100%", marginTop:"10px" }}>No Schools Found</Typography>
                                    }
                                </Box> : null
                            }
                        </Col>
                        <Col xs={12}>
                            <div className="mt-3">
                                <TextField required  sx={textFieldAndShrinkStyle} size="medium" name="user_reg_num" id="outlined-basic1" label="User Reg. No." variant="outlined" style={{ width: "100%" }} 
                                value={inputField.user_reg_num}
                                autoComplete="off" onChange={onChangeTxt} 
                                error={inputField.user_reg_num === ""}
                            helperText={
                                inputField.user_reg_num === ""
                                ? "User register number is required."
                                 : "" }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col container direction="column" rowSpacing={2} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>

                            {/* <Grid item xs={12} style={{ textAlign: "center" }}>
                            <label style={{ fontWeight: "bold" }}> Please Link your learning matters account (V3) </label>
                        </Grid>
                        <Grid item xs={12} alignItems="center" justifyContent="center">
                            <TextField size="medium" value={slctdSchl} name="schoolCode" id="outlined-basic" label="SCHOOL CODE" variant="outlined" style={{ width: "100%" }} onChange={onSearchSchool} /> <br />
                        </Grid> */}
                            {/* {
                            searchSchool ? <Grid item xs={12} className="d-flex justify-content-center" style={{ position: "relative" }}>
                                {
                                    filteredSchlLst.length >= 1 ? <div className="search-list-overlay">
                                        {
                                            filteredSchlLst.map((dt, index) => {
                                                return <Grid xs={12} key={index}>
                                                    <Button key={index} onClick={() => { onSchoolSelect(index); SetSearchScool(false); SetSlctSchlCode(dt.schoolDetails[0].schoolCodeForUsers) }} style={{ textAlign: "start" }}>
                                                        <p> <b>{dt.schoolDetails[0].schoolName} </b><span style={{ color: "#353535", textTransform: "initial" }}>{dt.schoolDetails[0].schoolAddress}</span></p>
                                                    </Button>
                                                </Grid>
                                            })
                                        }
                                    </div> : "No Schools Found"
                                }
                            </Grid> : null
                        } */}
                            {/* <Grid item xs={12} alignItems="center" justifyContent="center">
                            <TextField size="medium" value={slctSchlCode} name="user_reg_num" id="outlined-basic" label="REGISTRATION NUMBER" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
                        </Grid> */}
                            {/* {
                            isUserNew && userLoaded ? <div>
                                <Grid item xs={12} alignItems="center" justifyContent="center">
                                    <TextField size="medium" name="userPin" id="outlined-basic" type="pin" label="PIN" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
                                </Grid>
                                <Grid item xs={12} alignItems="center" justifyContent="center">
                                    <TextField size="medium" name="userPin" id="outlined-basic" type="pin" label="Create Password" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
                                </Grid>
                            </div> : <Grid item xs={12} alignItems="center" justifyContent="center">
                                <TextField size="medium" name="userPin" id="outlined-basic" type="pin" label="PASSWORD" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
                            </Grid>
                        } */}
                            {
                                userLoaded ? <Col item xs={12} className="mt-4">
                                    {
                                        isUserNew ? <Col container rowSpacing={2}>
                                            <Col item xs={12} className={"mt-4"}>
                                                <TextField 
                                                    sx={textFieldAndShrinkStyle} 
                                                    size="medium" 
                                                    name="otp" 
                                                    id="outlined-basic2" type="pin" 
                                                    label="OTP" variant="outlined" 
                                                    style={{ width: "100%" }} 
                                                    autoComplete="off" 
                                                    onChange={onChangeTxt}
                                                 />
                                            </Col>
                                            <Col item xs={12} className={"mt-4"}>
                                                <TextField 
                                                    sx={textFieldAndShrinkStyle} 
                                                    type={"password"} 
                                                    size="medium" 
                                                    name="userPassword" 
                                                    id="outlined-basic3" label="Create Password" variant="outlined" 
                                                    style={{ width: "100%" }} 
                                                    autoComplete="off" 
                                                    onChange={onChangeTxt} 
                                                    error={!!passwordError} 
                                                    
                                                />

                                            </Col>
                                            <Col item xs={12} className={"mt-4 d-flex justify-content-center"}>
                                                <TextField 
                                                    sx={textFieldAndShrinkStyle} 
                                                    type={"password"} size="medium" 
                                                    name="confirmPassword" 
                                                    id="outlined-basic4" 
                                                    label="Confirm Password" 
                                                    variant="outlined" 
                                                    style={{ width: "100%" }} autoComplete="off" 
                                                    error={!!passwordError} 
                                                    helperText={passwordError}
                                                    onChange={onChangeTxt} 
                                                />
                                            </Col>
                                            <Col item xs={12} className={"mt-4 d-flex justify-content-center"} >
                                                <ButtonComponent
                                                    disabled=
                                                    {!(slctdSchl && slctdSchl.length > 0 
                                                        && inputField.user_reg_num && inputField.user_reg_num.length > 0
                                                        && inputField.otp && inputField.otp.length > 0
                                                        && inputField.userPassword && inputField.userPassword.length > 0
                                                        && inputField.confirmPassword && inputField.confirmPassword.length > 0
                                                        && !passwordError
                                                    )}
                                                    onClick={onSubmitBtn}
                                                    aria-label="LOGIN"
                                                    >   
                                                         <LoginIcon fontSize={basic.iconsFontSize} style={{ marginTop:-1 }} />
                                                        <div style={{ marginLeft: 5 }}>LOGIN</div>                                                       
                                                </ButtonComponent>
                                                {/*} <Button variant={"contained"} onClick={onSubmitBtn}> LOGIN </Button> {*/}
                                            </Col>
                                        </Col> : <Col container rowSpacing={2}>
                                            <Col item xs={12} className={"mt-4"} >
                                                <TextField sx={textFieldAndShrinkStyle} 
                                                size="medium" 
                                                name="userPassword" 
                                                id="outlined-basic5" 
                                                type={"password"} 
                                                label="Password" 
                                                variant="outlined" 
                                                style={{ width: "100%" }} 
                                                autoComplete="off" onChange={onChangeTxt} />
                                            </Col>
                                            <Col item xs={12} className={"mt-4 d-flex justify-content-center"}>
                                                <ButtonComponent                                                
                                                    disabled=
                                                    {!(slctdSchl && slctdSchl.length > 0 
                                                        && inputField.user_reg_num && inputField.user_reg_num.length > 0
                                                        && inputField.userPassword && inputField.userPassword.length > 0
                                                    )}
                                                    onClick={onSubmitBtn}
                                                    aria-label="LOGIN"
                                                    >   
                                                         <LoginIcon fontSize={basic.iconsFontSize} style={{ marginTop:-1 }} />
                                                        <div style={{ marginLeft: 5 }}>LOGIN</div>                                                       
                                                </ButtonComponent>
                                               {/*} <Button variant={"contained"} onClick={onSubmitBtn}> LOGIN </Button> {*/}
                                            </Col>
                                        </Col>
                                    }
                                </Col> : <Col item xs={12} className={"mt-4 d-flex justify-content-center"}>
                                    <ButtonComponent
                                        disabled={!(slctdSchl && slctdSchl.length > 0 && inputField.user_reg_num && inputField.user_reg_num.length > 0)}
                                        onClick={onNextBtn}
                                        aria-label="Next"
                                    >   
                                         <div style={{ marginLeft: 5 }}>Next</div>
                                         <NavigateNextIcon fontSize={basic.iconsFontSize} style={{ marginTop:-1 }} />
                                    </ButtonComponent>
                                </Col>
                            }

                            <Col item xs={12} sx={{ fontFamily:basic.fontFamily, fontSize:basic.contentFontSize}} style={isUserValid ? { textAlign: "center", display: "none" } : { textAlign: "center", display: "block" }}>
                                <div>
                                    {
                                        isUserValid ? <label style={{ fontWeight: "bold", color: "red" }}>{errorTxt} </label> :
                                            <label style={{ fontWeight: "bold", color: "red" }}>
                                                {errorTxt}
                                            </label>
                                    }
                                </div>
                            </Col>

                            <Col item xs={12} alignItems="center" justifyContent="center" style={{ textAlign: "center" }}>
                                <span style={{ fontSize: basic.contentFontSize, color: "red" }}>
                                    {errorTxt}
                                </span>
                            </Col>
                            {/* <Col item xs={12} container alignItems="center" justifyContent="center">
                            <p>Dont't have an account? <a href="/register"> Register </a></p>
                        </Col> */}
                        </Col>

                    </Row>
                </Container>
            </animated.div>
        </div>
    );
}

export default Alexalogin;













































// import { useEffect, useState } from "react";
// import React from "react";
// import { Autocomplete, Button, Container, Grid, TextField } from "@mui/material";
// import logoImg from "../Assets/logo.png";
// import axios from "axios";
// import { useSpring, animated } from "react-spring";
// import { useNavigate } from "react-router-dom";
// import NavBar from "../navBar";
// import flatten from 'flat'
// import Loading from "../CmnCompnts/Loading";
// function Alexalogin(props) {
//     const navigate = useNavigate();
//     const [inputField, setInputField] = useState({
//         // user_name: "",
//         user_email: "",
//         user_pin: "",
//         user_password: "",
//         // user_type: "DEFAULT",
//         school_code: ""
//     });

//     const [isUserValid, SetisUserValid] = useState(true)
//     const [codeForOauth, SetCodeForOauth] = useState("")
//     const [loading, SetLoading] = useState(false)
//     const [errorTxt, SeterrorTxt] = useState("")
//     const [schoolLists, SetSchoolLists] = useState([])
//     const [searchSchool, SetSearchScool] = useState(false)
//     const [filteredSchlLst, SetFilteredSchlLst] = useState([])
//     const [slctdSchl, SetSlctSchl] = useState("")
//     const [slctSchlCode, SetSlctSchlCode] = useState("")
//     const [isUserNew, setIsUserNew] = useState(true)
//     const [userLoaded, setUserLoaded] = useState(false)


//     const anim = useSpring({
//         to: { opacity: 1 },
//         from: { opacity: 0 },
//         config: { duration: 1000 },
//     });

//     //taking inputs from user and assigning to respective variable
//     const onChangeTxt = (e) => {
//         if (e.target.name == "user_reg_num") {
//             if (e.target.value.length >= 13) {
//                 let scholCode = e.target.value.slice(0, 14);
//                 let roleNum = e.target.value.substring(14);
//                 SetSlctSchlCode(scholCode + roleNum)
//             }
//             //   SetChangeColor(e.target.id)
//         }
//         setInputField({
//             ...inputField,
//             [e.target.name]: e.target.value,
//         });
//     };

//     const onSearchSchool = (e) => {
//         SetSlctSchl(e.target.value)
//         SetSearchScool(true);
//         const filtredLst = schoolLists.filter((dt) => {
//             return dt[0].school_name.toLowerCase().includes(e.target.value)
//         })
//         SetFilteredSchlLst(filtredLst)
//     }

//     useEffect(() => {
//         //Loading all shcools for search
//         // axios.get("/users/getAllSchools")
//         //     .then(res => {
//         //         console.log("All Schools list recieved:", res.data);
//         //         SetSchoolLists(res.data)
//         //     })
//         //     .catch(err => {
//         //         console.log("Error when tried to access schools list:", err);
//         //     })
//         axios.get("/lmAccounts/getAllSchools")
//             .then(res => {
//                 console.log("All Schools list recieved:", res.data);
//                 SetSchoolLists(res.data)
//             })
//             .catch(err => {
//                 console.log("Error when tried to access schools list:", err);
//             })
//     }, [])

//     //Login button
//     const onSubmitBtn = (e) => {
//         e.preventDefault();
//         SetLoading(true);
//         const search = window.location.search;
//         const params = new URLSearchParams(search);
//         const redirect_uri = params.get("redirect_uri");
//         const state = params.get("state");
//         const code = params.get("code");
//         // let payload = {
//         //     username: inputField.user_email,
//         //     school_code: inputField.school_code,
//         //     password: inputField.user_pin,
//         // };

//         let payload = {
//             regNum: inputField.user_reg_num,
//             user_pin: inputField.user_pin,
//             user_password: inputField.user_password,
//         };
//         if (isUserNew) {
//             axios.post('/alexa/updatePwd', payload).then((res) => {
//                 if (res.data.verfied) {
//                     linkiAlexa()
//                 }
//                 else {
//                     SeterrorTxt(res.data.message)
//                     console.log("Not verified", res.data);
//                 }
//                 SetLoading(false)
//             })
//         }
//         else {
//             axios.post('/alexa/verifyPwd', payload).then((res) => {
//                 if (res.data.verfied) {
//                     linkiAlexa()
//                 }
//                 else {
//                     SeterrorTxt(res.data.message)
//                     console.log("Not verified", res.data);
//                 }
//                 SetLoading(false)
//             })
//         }

//         // axios
//         //     .get("/alexalogin", payload)
//         //     .then((res) => {
//         //         window.location.replace(
//         //             `${redirect_uri}?state=${state}&code=${code}`
//         //         );
//         //         // if (res.data.result === "success") {
//         //         //     console.log("Login Successfully");
//         //         //     window.location.replace(
//         //         //         `${redirect_uri}?state=${state}&code=${code}`
//         //         //     );
//         //         //     SetLoginStatus("Login Status: Login SuccessFul")
//         //         // } else if (res.data.result === "error") {
//         //         //     console.log("Login Failure");
//         //         //     SetLoginStatus("Login Status: Enter correct credentials")
//         //         // }
//         //     })
//         //     .catch((error) => console.log("we got error", error));
//     };

//     const linkiAlexa = (e) => {
//         const search = window.location.search;
//         const params = new URLSearchParams(search);
//         const redirect_uri = params.get("redirect_uri");
//         const state = params.get("state");
//         const code = params.get("code");

//         axios.post("/alexa/updateAccountLinkStatus").then((res) => {
//             console.log(res.data.message);
//         });

//         window.location.replace(
//             `${redirect_uri}?state=${state}&code=${codeForOauth}`
//         );
//     }

//     const onSchoolSelect = (e) => {
//         console.log("Selected school: ", filteredSchlLst[e][0].school_name);
//         SetSearchScool(false)
//         SetSlctSchl(filteredSchlLst[e][0].school_name)
//     }


//     const onNextBtn = (e) => {
//         e.preventDefault()
//         let payload = {
//             regNum: inputField.user_reg_num,
//             school_code: slctdSchl
//         };
//         axios.post("/alexa/alexaLoginVerifyUser", payload)
//             .then((res) => {
//                 console.log("ssssssssss", res.data);
//                 if (res.data.message != "") {
//                     SeterrorTxt(res.data.message)
//                     SetCodeForOauth(res.data.code)
//                 }
//                 else {
//                     setUserLoaded(true)
//                     SetCodeForOauth(res.data.code)
//                     res.data.isUserAlreadyExist ? setIsUserNew(false) : setIsUserNew(true)
//                 }
//             }).catch((err) => {
//                 console.log("--------", err);
//             })
//         // console.log("dddddddddddddd", payload);
//     }

//     return (
//         <div>
//             <animated.div style={anim} id="REGISTER_FORM">
//                 {loading ? <Loading /> : null}
//                 <Container maxWidth={"xs"}>
//                     <Grid container direction="column" rowSpacing={2} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
//                         <Grid item xs={12} alignItems="center" justifyContent="center">
//                             <img src={logoImg} alt="logoImg" style={{ width: "150px", margin: "0px auto", display: "block" }} ></img>
//                         </Grid>
//                         <Grid item xs={12} style={{ textAlign: "center" }}>
//                             <label style={{ fontWeight: "bold" }}> Please Link your learning matters account (V3) </label>
//                         </Grid>
//                         <Grid item xs={12} alignItems="center" justifyContent="center">
//                             <TextField size="medium" value={slctdSchl} name="school_code" id="outlined-basic" label="SCHOOL CODE" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onSearchSchool} /> <br />
//                             {/* <label style={{width:"100%", textAlign:"right", fontWeight:"bolder", color:"green", paddingTop:"3px"}}>Verified</label> */}
//                         </Grid>
//                         {
//                             searchSchool ? <Grid item xs={12} className="d-flex justify-content-center" style={{ position: "relative" }}>
//                                 {
//                                     filteredSchlLst.length >= 1 ? <div className="search-list-overlay">
//                                         {
//                                             filteredSchlLst.map((dt, index) => {
//                                                 return <Grid xs={12} key={index}>
//                                                     <Button key={index} onClick={() => { onSchoolSelect(index); SetSearchScool(false); SetSlctSchlCode(dt[0].school_code_for_users) }} style={{ textAlign: "start" }}>
//                                                         <p> <b>{dt[0].school_name} </b><span style={{ color: "#353535", textTransform: "initial" }}>{dt[0].school_address}</span></p>
//                                                     </Button>
//                                                 </Grid>
//                                             })
//                                         }
//                                     </div> : "No Schools Found"
//                                 }
//                             </Grid> : null
//                         }
//                         <Grid item xs={12} alignItems="center" justifyContent="center">
//                             {/* <TextField size="medium" name="user_email" id="outlined-basic" label="EMAIL" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} /> */}
//                             <TextField size="medium" value={slctSchlCode} name="user_reg_num" id="outlined-basic" label="REGISTRATION NUMBER" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
//                         </Grid>
//                         {/* {
//                             isUserNew && userLoaded ? <div>
//                                 <Grid item xs={12} alignItems="center" justifyContent="center">
//                                     <TextField size="medium" name="user_pin" id="outlined-basic" type="pin" label="PIN" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
//                                 </Grid>
//                                 <Grid item xs={12} alignItems="center" justifyContent="center">
//                                     <TextField size="medium" name="user_pin" id="outlined-basic" type="pin" label="Create Password" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
//                                 </Grid>
//                             </div> : <Grid item xs={12} alignItems="center" justifyContent="center">
//                                 <TextField size="medium" name="user_pin" id="outlined-basic" type="pin" label="PASSWORD" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
//                             </Grid>
//                         } */}
//                         {
//                             userLoaded ? <Grid item xs={12} >
//                                 {
//                                     isUserNew ? <Grid container rowSpacing={2}>
//                                         <Grid item xs={12} alignItems="center" justifyContent="center">
//                                             <TextField size="medium" name="user_pin" id="outlined-basic" type="pin" label="PIN" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
//                                         </Grid>
//                                         <Grid item xs={12} alignItems="center" justifyContent="center">
//                                             <TextField size="medium" name="user_password" id="outlined-basic" type="pin" label="Create Password" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
//                                         </Grid>
//                                         <Grid item xs={12} container alignItems="center" justifyContent="center">
//                                             <Button variant={"contained"} onClick={onSubmitBtn}> SUBMIT </Button>
//                                         </Grid>
//                                     </Grid> : <Grid container rowSpacing={2}>
//                                         <Grid item xs={12} alignItems="center" justifyContent="center">
//                                             <TextField size="medium" name="user_password" id="outlined-basic" type="pin" label="PASSWORD" variant="outlined" style={{ width: "100%" }} autoComplete="off" onChange={onChangeTxt} />
//                                         </Grid>
//                                         <Grid item xs={12} container alignItems="center" justifyContent="center">
//                                             <Button variant={"contained"} onClick={onSubmitBtn}> SUBMIT </Button>
//                                         </Grid>
//                                     </Grid>
//                                 }
//                             </Grid> : <Grid item xs={12} container alignItems="center" justifyContent="center">
//                                 <Button variant={"contained"} onClick={onNextBtn}> NEXT </Button>
//                             </Grid>
//                         }

//                         <Grid item xs={12} style={isUserValid ? { textAlign: "center", display: "none" } : { textAlign: "center", display: "block" }}>
//                             {isUserValid ? <label style={{ fontWeight: "bold", color: "red" }}>{errorTxt} </label> :
//                                 <label style={{ fontWeight: "bold", color: "red" }}>
//                                     {errorTxt}
//                                 </label>}
//                         </Grid>

//                         <Grid item xs={12} alignItems="center" justifyContent="center">
//                             <span style={{ fontSize: "16px", color: "red", fontWeight: "800" }}>
//                                 {errorTxt}
//                             </span>
//                         </Grid>

//                         {/* <Grid item xs={12} container alignItems="center" justifyContent="center">
//                             <p>Dont't have an account? <a href="/register"> Register </a></p>
//                         </Grid> */}
//                     </Grid>
//                 </Container>
//             </animated.div>
//         </div>
//     );
// }

// export default Alexalogin;