import React, { useState, useEffect } from 'react';
import {  Box, IconButton, Grid, TextField, FormControlLabel  } from '@mui/material';
import { Save } from '@mui/icons-material';
import axios from 'axios';
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import PageHeading from '../page_heading';
import BackButton from '../common/back_button';
import CustomButton2 from "../common/button_two";
import ModelPopup from "../common/modal_popup";
import SwitchStyle from '../SwitchStyle';
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import { basic, textFieldAndShrinkStyle } from '../../../themes/basic';

const EditProduct = () => {
  const { id } = useParams(); 
  const [editId, setEditId] = useState(null);
  const [product, setProduct] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [messageStatus, setMessageStatus] = useState(null);
  const [__productExists, __setProductExists] = useState(false); 
  const [__checkMessage, __setCheckMessage] = useState(''); 
  const [__errorMessage, __setErrorMessage] = useState('');
  const [__isProductNameValid, __setIsProductNameValid] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const navigate = useNavigate() 

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const __checkProductExists = async (name) => {
    try {
      const productResponse = await axios.post("/product/checkproductname", { productName: name, productId: editId });
      if (productResponse.data.exists){        
        __setProductExists(true);
        __setIsProductNameValid(false); 
        __setCheckMessage("This product name is already in use.");              
      }else{      
        __setIsProductNameValid(true); 
        __setProductExists(false);        
        __setCheckMessage("Product name is available.");
      }
    } catch (error) {
      __setIsProductNameValid(false);
        __setProductExists(false);        
        console.error("Error checking product name:", error);
    }
  };

  const __handleProductNameChange = (aEventObject) => {
    const _productValue = aEventObject.target.value;
    const _regex = /^[a-zA-Z0-9 ]*$/;  
    
    

    if (_productValue.toLowerCase() === "all") {
        __setIsProductNameValid(false);
        __setErrorMessage('Product name "All" or "all" is not allowed.');
        __setProductExists(false);
        __setCheckMessage('');
        
        return; 
    }

    if (!_regex.test(_productValue)) {
       __setIsProductNameValid(false);
        __setErrorMessage('Special characters are not allowed.');
        __setProductExists(false);
        __setCheckMessage('');
        return; 
    }

    __setErrorMessage("");
    setProductName(_productValue);
    
    if (_productValue.length > 0) {
      __checkProductExists(_productValue); 
    } else {
      __setIsProductNameValid(true);
      __setProductExists(false);
      __setCheckMessage('');
    }
  };

  const updateProductFunc = async (e) => {
    e.preventDefault();
    setModalOpen(false);
    setLoading(true);

    let productData = {
      productName: productName,
      productDescription: productDescription,
      //isVisible: isVisible
    };
    
    const updateProductData = await axios.put(`/product/update/${id}`, productData);
    console.log(updateProductData);
    if(updateProductData.data.status=="success"){
      setLoading(false);
      triggerSnackbarMessage("The product has been updated successfully.","success"); 
    }else{
      setLoading(false);
      triggerSnackbarMessage("Failed to update product.","error"); 
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const triggerSnackbarMessage = (message, variant) => {
    setSnackbarMessage(message);
    setSnackbarVariant(variant);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/product/${id}`);
        console.log("fetchProduct",response.data.data)
        setProduct(response.data.data);
        setProductName(response.data.data.productName);
        setProductDescription(response.data.data.productDescription);
        //setIsVisible(response.data.data.isVisible);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    fetchProduct();
    setEditId(id);
  }, [id]);

  return (
    <div style={{ marginTop: "0",position:"relative" }}> 
   
      <PageHeading title="Edit Product" style={{marginBottom:"20px"}} />
      <Box
        sx={{
          height: "auto",
          width: "60%",
          margin:"auto auto auto auto",
          marginTop:"0px",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
       >
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item sm={12}>
              <TextField
                id="product"
                label="Product Name"
                variant="outlined"
                size="small"
                required
                type="text"
                style={{ width: "100%" }}
                value={productName || ""} 
                onChange={__handleProductNameChange}
                sx={textFieldAndShrinkStyle}
                error={!!__errorMessage || productName === ""}
                helperText={__errorMessage || (productName === "" ? "Product name is required." : "")}
              />

              
            {__errorMessage ==="" && productName && <div style={{ fontSize:basic.contentFontSize, color: __productExists ? 'red' : 'green' }}>{__checkMessage}</div>}


              <TextField
                id="description"
                label="Product Description"
                value={productDescription || ""}
                variant="outlined"
                size="small"
                required
                type="text"
                multiline
                rows={4}
                style={{ width: "100%", marginTop:"20px", marginBottom:"20px" }}
                onChange={(e) => setProductDescription(e.target.value)}
                sx={textFieldAndShrinkStyle}
                error={productDescription === ""}
                helperText={
                  productDescription === ""
                    ? "Product description is required."
                    : ""
                }
              />
              <div style={{ margin: 10 }} />
              
             {/* <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: 8, fontSize:16, marginTop:-4 }}><strong>Status:</strong> </span>
                <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <SwitchStyle
                    size="small"
                    checked={isVisible}
                    onChange={(e) => {
                      setIsVisible(e.target.checked);
                    }}
                  />
                }
                label={isVisible ? "Active" : "Inactive"}
                />
              </div> */}


              <Grid container spacing={2} style={{ marginBottom: 20, marginLeft:"0px" }}>
                <CustomButton2
                onClick={handleOpen}
                disabled={
                  !(__isProductNameValid && productDescription && productDescription.length > 0) ||
                  __productExists // Disable button if product exists
                }
                 aria-label="Save"
                >
                  <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }}/>
                  <div style={{ marginLeft: 5 }}>Save</div>
                </CustomButton2>
                <BackButton backToPage="/adminDashboard/product" aButtonText="Cancel" />
              </Grid>
            
            </Grid>
            <Grid item sm={4}>
            </Grid>
        </Grid>
      </Box>

      <ModelPopup
        open={modalOpen}
        onClose={handleClose}
        onConfirm={updateProductFunc}
        title="Alert"
        description="Are you sure you want to update?"
        loading={loading}
        successMsg={successMsg}
        successStatus={messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
        onGoToNext={() => { navigate("/adminDashboard/product") }}
        onFinalButtonText="Go to Products"
      />

      <LoadingOverlay loading={loading} />

      <MessageSnackbar
        message={snackbarMessage}
        variant={snackbarVariant}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      
    </div>
  );
};

export default EditProduct;
