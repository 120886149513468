
/* define the common styles like fontSize, fontFamily and etc... */
export const basic = {
  colorWhite: "#ffffff",
  colorBlack: "#000000",
  greenColor:"#90bb41",
  tableBgColor: "#F3F3F3",
  stepNormalLine:"#bdbdbd",
  logoGreyColor:"#646c7c",
  redColor:"#f4492c",
  redLightColor:"#ff7f7f",
  redHoverColor:"#9e1b1b",
  blueColor:"#3700B3",
  blueHoverColor:"#6200BE",   
  fontSize12: "12px",
  fontSize13: "13px",
  fontSize14: "12px",
  fontSize16: "16px",
  fontSize18: "18px",
  fontSize20: "20px",
  tableHeaderSize:"14px", 
  
  buttonLightGrey: "#f6f6f6",
  buttonBgColorNormal: "#f6f6f6",
  buttonFontSize:"12px",
  labelFontSize:"10px",
  leftPaneMenuFontSize:"12px",
  contentFontSize:"12px",
  headingFontSize: "14px",
  iconsFontSizeSmall: "14px",
  iconsFontSize: "16px",
  iconsFontSizeLarge: "18px",
  buttonWidth:"120px",
  fontFamily:"CircularStd-Book !important",
};
/* define the common styles like fontSize, fontFamily and etc... */

/* sidebar menu hover & active */
export const activeStyle = {
  backgroundColor: basic.greenColor,
  fontFamily:basic.fontFamily,
  color: basic.colorWhite,
  '& .MuiListItemIcon-root': {
    color: basic.colorWhite,
    fontFamily:basic.fontFamily,
    minWidth:"25px",
    '& svg': {
      fontSize: basic.leftPaneMenuFontSize,
      }
  },
  '& .MuiListItemText-root span': {
    fontSize: basic.leftPaneMenuFontSize, // Text font size when active
    fontFamily:basic.fontFamily,
  },
  '&:hover': {
    backgroundColor: basic.greenColor,
    color: basic.colorWhite,
  },
};

export const hoverStyle = {
  color: basic.colorBlack,
  '& .MuiListItemIcon-root': {
    color: basic.colorBlack,
    fontFamily:basic.fontFamily,
    minWidth:"25px",
    '& svg': {
      fontSize: basic.leftPaneMenuFontSize,
      }
  },
  '& .MuiListItemText-root span': {
    fontSize: basic.leftPaneMenuFontSize,
    fontFamily:basic.fontFamily,
  },
  '&:hover': {
    backgroundColor: basic.greenColor,
    color: basic.colorWhite,
    '& .MuiListItemIcon-root': {
      color: basic.colorWhite,
      fontFamily:basic.fontFamily,
      '& svg': {
        color: basic.leftPaneMenuFontSize,
      }
    },
    '& .MuiListItemText-root span': {
      fontSize: basic.leftPaneMenuFontSize,
      fontFamily:basic.fontFamily,
    },
  },
};
/* sidebar menu hover & active */

/* TextField type:date format */
export const textFieldWithLabel = {
  height: "25px",
  width: "105px",
  "& .MuiOutlinedInput-root": {
    "& input": {
      padding: "5px 3px",
      fontSize: basic.contentFontSize,
      fontFamily:basic.fontFamily,
    },
    "& fieldset": {
      borderColor: basic.logoGreyColor
    },
    "&:hover fieldset": {
      borderColor: basic.greenColor
    },
    "&.Mui-focused fieldset": {
      borderColor: basic.greenColor
    }
  },
  "& label.Mui-focused": {
    color: basic.greenColor
  },
  marginRight: "10px",
  marginTop:"5px",
  marginBottom:"5px",
};

export const textFieldAndShrinkStyle = {
    "& .MuiInputLabel-formControl":{
      fontFamily:basic.fontFamily,
      fontSize:basic.contentFontSize,
    },
    "& .MuiOutlinedInput-input":{
      fontFamily:basic.fontFamily,
      fontSize:basic.contentFontSize,
    },
    "& .MuiInputLabel-shrink": {
      marginTop:"4px",
    },
    '& .MuiFormHelperText-root': {
      fontFamily:basic.fontFamily,
      fontSize:basic.contentFontSize,
      margin:"3px 0px 0px 0px"
    },
    '& .MuiOutlinedInput-notchedOutline>legend': {
      fontSize:"0.58em"
    }
}
