import React, { useState, useEffect } from 'react';
import {  Box, Grid, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import PageHeading from "../page_heading";
import BackButton from "../common/back_button";
import CustomButton2 from "../common/button_two";
import ModelPopup from "../common/modal_popup"
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import { basic, textFieldAndShrinkStyle } from '../../../themes/basic';

const AddProduct = () => {
  const [__productName, __setProductName] = useState(null);
  const [__productDescription, __setProductDescription] = useState(null);
  const [__modalOpen, __setModalOpen] = useState(false);
  const [__loading, __setLoading] = useState(false);
  const [__productExists, __setProductExists] = useState(false); 
  const [__checkMessage, __setCheckMessage] = useState(''); 
  const [__errorMessage, __setErrorMessage] = useState('');
  const [__isProductNameValid, __setIsProductNameValid] = useState(false);

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success");

  const __navigate = useNavigate() 
  const __saveButtonClick = () => __setModalOpen(true);
  const __savePopupClose = () => __setModalOpen(false);

  const __checkProductExists = async (aProductInputValue) => {
    try {
      const _productResponse = await axios.post("/product/checkproductname", { productName: aProductInputValue });
      if (_productResponse.data.exists){
        __setIsProductNameValid(false);
        __setProductExists(true);
        __setCheckMessage("This product name is already in use.");        
      }else{
        //console.log(__errorMessage);
        __setProductExists(false);
        __setIsProductNameValid(true);
        __setCheckMessage("Product name is available.");
      }

      console.log(__isProductNameValid);
    } catch (aError) {
      __setProductExists(false);
      __setIsProductNameValid(false);
      console.error("Error checking product name:", aError);
    }
  };


  const __handleProductNameChange = (aEventObject) => {
    const _productValue = aEventObject.trim();
    const _regex = /^[a-zA-Z0-9 ]*$/;  

    
    console.log(_productValue.toLowerCase());
    if (_productValue.toLowerCase() === "all") {
        __setErrorMessage('Product name "All" or "all" is not allowed.');
        __setProductExists(false);
        __setCheckMessage('');
        __setIsProductNameValid(false);
        return; 
    }
    
    if (!_regex.test(_productValue)) {
        __setErrorMessage('Special characters are not allowed.');
        __setProductExists(false);
        __setCheckMessage('');
        __setIsProductNameValid(false);
        return; 
    }

      __setErrorMessage("");
      __setProductName(_productValue);
      
      if (_productValue.length > 0) {
        __setIsProductNameValid(false);
          __checkProductExists(_productValue);
      } else {
        __setIsProductNameValid(true);
          __setProductExists(false);
          __setCheckMessage('');
      }

  };

  const __handleProductDescriptionChange = (aDescriptionValue) => {    
    __setProductDescription(aDescriptionValue);
  };


  const __addProductFunc = async (aProp) => {
    __setModalOpen(false);
    aProp.preventDefault();
    __setLoading(true);

    let _productData = {
      productName: __productName,
      productDescription: __productDescription,
    };
    
    const _addProductResponse = await axios.post("/product/add", _productData);
    if(_addProductResponse.data.status === "success"){
      __setProductName("");
      __setProductDescription("");    
      __setLoading(false);
      __triggerSnackbar("The products has been successfully added.","success"); 
    }else{
      __setLoading(false);
      __triggerSnackbar("Failed to add product.","error"); 
    }
  };

  const __handleSnackbarClose = (aEvent, aReason) => {
    if (aReason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __triggerSnackbar = (message, variant) => {
    __setSnackbarMessage(message);
    __setSnackbarVariant(variant);
    __setSnackbarOpen(true);
  };

  useEffect(() => {
    // Fetch products from API
  }, []);

  return (
    <div style={{ marginTop: "0",position:"relative" }}> 
    
      <PageHeading title="Add Product" style={{marginBottom:"20px"}} />
      <Box
        sx={{
          height: "auto",
          width: "60%",
          margin:"auto auto auto auto",
          marginTop:"0px",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
       >
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item sm={12}>
              <TextField
                id="product"
                label="Product Name"
                variant="outlined"
                size="small"
                required
                type="text"
                style={{ width: "100%",  fontFamily:basic.fontFamily }}
                sx={textFieldAndShrinkStyle}
                value={__productName}
                //onChange={__handleProductNameChange}
                onChange={(e) => __handleProductNameChange(e.target.value)}
                error={!!__errorMessage || __productName === ""}
                helperText={__errorMessage  || (__productName === "" ? "Product name is required." : "")}
              />

            {__errorMessage ==="" && __productName && <div style={{ fontSize:basic.contentFontSize, color: __productExists ? 'red' : 'green' }}>{__checkMessage}</div>}

              <TextField
                id="description"
                label="Product Description"
                value={__productDescription}
                variant="outlined"
                size="small"
                required
                type="text"
                multiline
                rows={4}
                style={{ width: "100%", marginTop:"20px", marginBottom:"20px",  fontFamily:basic.fontFamily }}
                sx={textFieldAndShrinkStyle}
                onChange={(e) => __setProductDescription(e.target.value)}
                error={__productDescription === ""}
                helperText={
                  __productDescription === ""
                    ? "Product description is required."
                    : ""
                }
              />
              <div style={{ margin: 10 }} />
          

              <Grid container spacing={2} style={{ marginBottom: 20, marginLeft:"0px" }}>
                <CustomButton2
                  onClick={__saveButtonClick}
                  disabled={!(__isProductNameValid && __productDescription && __productDescription.length > 0) ||
                  __productExists 
                  }
                  aria-label="Save"
                  >
                  <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
                  <div style={{ marginLeft: 5 }}>Save</div>
                </CustomButton2>
                <BackButton backToPage="/adminDashboard/product" aButtonText="Cancel" />
          
              </Grid>
          
            </Grid>
            <Grid item sm={4}>
            </Grid>
        </Grid>
      </Box>

      <ModelPopup
        open={__modalOpen}
        onClose={__savePopupClose}
        onConfirm={__addProductFunc}
        title="Alert"
        description="Are you sure you want to add this product?"
        loading={__loading}
        confirmText="Confirm"
        cancelText="Cancel"
        onGoToNext={() => { __navigate("/adminDashboard/product") }}
        onFinalButtonText="Go to Products"
      />

      <LoadingOverlay loading={__loading} />

      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />
    </div>
  );
};

export default AddProduct;
