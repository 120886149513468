import { React, useContext, useEffect, useState } from 'react';
import LogoImage from '../../src/assets/images/lm-logo.png';
import { useNavigate } from 'react-router-dom';
import { Container, Navbar, NavbarBrand, Dropdown } from 'react-bootstrap';
import { UserContext } from './Context/Reducer';
//import AvatarIcon from './Assets/avatar.svg'; // Add an avatar image
import AvatarIcon from '@mui/material/Avatar';
import {Typography} from '@mui/material';
import {Home as HomeIcon } from '@mui/icons-material';

//import ChangeAndUpdatePassword from './Admin/PasswordCompnts/ChangePassword_old';
import useGlobalData from '../Zustand/GlobalData';
import DashboardBtnImg from '../components/Assets/dashboard.svg';
import PasswordModal from './Admin/PasswordCompnts/change_password';
import { basic } from '../themes/basic';

function NavBarPage() {
    let navigate = useNavigate();

    const [isItAdmin, setIsItAdmin] = useState(false);
    const [show, setShow] = useState(false);
    const { env } = useGlobalData();
    const { state, dispatch } = useContext(UserContext);

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("changePassword");

    const handleOpenModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        console.log("zzzzzzzzzzzzzzzz", state);
    }, [state]);

    const onLogout = (e) => {
        console.log("working");
        e.preventDefault();
        localStorage.removeItem("adminId");
        localStorage.removeItem("_accountId");
        localStorage.removeItem("isAdminLogged");
        localStorage.removeItem("lmAccountId");
        localStorage.removeItem("UserEmail");
        
        //navigate('/login');
        navigate('/adminlogin');
    };

    const handleProfileClick = () => {
        navigate('/userProfile'); // Assuming you have a user profile route
    };
    

    return (
        <div>
        <Navbar className='fixed-top' style={{ height: "50px", borderBottom: "1px solid #cacaca", backgroundColor: "white" }} >
            <Container fluid>
                <Navbar.Brand onClick={() => navigate("/adminDashboard")}>
                    <div className='d-flex align-items-center'>
                    <img src={LogoImage} style={{ width: "50px", cursor: "pointer" }} alt="Logo"  />
                    <Typography title='Home' variant="h6" component="div" onClick={() => navigate('/adminDashboard')}  style={{ cursor:"pointer", marginLeft:"5px", fontSize:basic.fontSize16,fontWeight:"bold", fontFamily:basic.fontFamily, color: "#000000", textAlign:"center", width:"100%" }}>
                    Kosmos
                    </Typography>
                        {/*}<img src={LogoImage} style={{ width: "100px", cursor: "pointer" }} alt="Logo"  /> {*/}
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">                   
                    <Navbar.Text>
                        <div className='d-flex'>
                            <HomeIcon titleAccess='Home' style={{ cursor:"pointer"}} onClick={() => navigate('/adminDashboard')} />
                            { /* } <button className='btn-latest me-2' onClick={() => navigate('/adminDashboard')}>
                                <img style={{ color: "white" }} src={DashboardBtnImg} alt="Dashboard" /> Main Dashboard
                            </button>
                           <button className='btn-latest me-2' onClick={(e) => {
                                e.preventDefault();
                                setShow(true);
                            }}>
                                Change Password
                            </button> {*/} 
                        </div>
                    </Navbar.Text>
                    <Navbar.Text>                        
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className="d-flex align-items-center" style={{ backgroundColor: "transparent", border: "none" }} 
                            sx={{
                                "& .dropdown-toggle:after": {
                                    display:"inherit",
                                },
                                "& .dropdown-toggle:focus": {
                                    border:"0px solid",
                                },
                            }}>
                                <span className='ms-2' style={{ fontSize:basic.headingFontSize}} >{state.userRole === 'admin' ? 'Admin' : 'Instructor'}</span>
                                <AvatarIcon style={{ marginLeft:"5px"}} sx={{ width:"30px", height:"30px"}}  />
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ marginLeft:"-15px", marginTop:"4px", borderTop:"0px solid", borderTopLeftRadius:"0px", borderTopRightRadius:"0px"}}>                                
                                <Dropdown.Item style={{ fontSize:basic.leftPaneMenuFontSize}} onClick={() => handleOpenModal("changePassword")}>Change Password</Dropdown.Item>
                                <Dropdown.Item style={{ fontSize:basic.leftPaneMenuFontSize}} onClick={onLogout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <PasswordModal
            show={showModal}
            actionType={modalType}
            onClose={handleCloseModal}
        />
    </div>
    );
}

export default NavBarPage;
