// import React, { useState } from 'react'
// import NavBar from '../../navBar';
// import { Col, Row } from 'react-bootstrap';
// import PrimaryTextBox from '../../CmnCompnts/TextBox';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// export default function RegisterSchoolAdmin() {
//   const [errMsg, setErrMsg] = useState("")
//   const [inputField, setInputField] = useState({
//     schoolName: "",
//     schoolCode: "",
//     schoolPhoneNum: "",
//     adrs_l1: "",
//     adrs_l2: "",
//     adrs_city: "",
//     adrs_pin_code: "",
//   });

//   const onChangeTxt = (e) => {
//     setInputField({
//       ...inputField,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const navigate = useNavigate()

//   const onSubmit = () => {
//     let generateSchoolCode = `${inputField.adrs_state.slice(0, 3) + "_" + inputField.adrs_city.slice(0, 3) + "_" + inputField.schoolName.slice(0, 3)}`
//     let payload = {
//       userEmail: inputField.userEmail,
//       schoolName: inputField.schoolName,
//       schoolCode: inputField.schoolCode,
//       schoolPhoneNum: inputField.schoolPhoneNum,
//       address: inputField.adrs_l1 + " " + inputField.adrs_l2 + " " + inputField.adrs_city + " " + inputField.adrs_pin_code,
//       schoolCodeForUsers: inputField.schoolCode,
//       udiseCode: inputField.udiseCode,
//       schoolAbbreviation: inputField.schoolAbbreviation,
//       schoolPrefix: "",
//     }
//     console.log("Generate school code", generateSchoolCode);
//     axios
//       .post("/lmAccounts/addSchoolByAdmin", payload)
//       .then((res) => {
//         localStorage.setItem("_accountId", res.data.accountData._id)
//         console.log("School And account Created: ", res.data);
//         if (res.data.err === "") {
//           setErrMsg("School Created!")
//         }
//         setTimeout(() => {
//           navigate('/schoolDashboard/profile')
//         }, 1000);
//       })
//       .catch((error) => {
//         console.log(error)
//         setErrMsg("Email Already Exist")
//         setTimeout(() => {
//           setErrMsg("")
//         }, 1000);
//       });
//   }

//   return (
//     <div>
//       {/* <div>
//         <NavBar />
//       </div> */}
//       <Row>
//         <Col xs={6}>
//           <div>

//           </div>
//         </Col>
//       </Row>
//       <Row>
//         <Col xs={6} className='m-auto mt-5'>
//           <div className='mb-2 pb-2 text-center'>
//             <h4><b>Add New School</b></h4>

//             <div className='add-new-school-form mt-3'>
//               <Row>
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="userEmail" label="Enter UserEmail" onChange={onChangeTxt} />
//                 </Col>
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="schoolName" label="School NAME" onChange={onChangeTxt} />
//                 </Col>
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="udiseCode" label="UDISE Code" onChange={onChangeTxt} />
//                 </Col>
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="schoolAbbreviation" label="School Abbreviation" onChange={onChangeTxt} />
//                 </Col>
//                 {/* <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="schoolPrefix" label="School Prefix" onChange={onChangeTxt} />
//                 </Col> */}
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="schoolCode" label="School CODE (Unique)" onChange={onChangeTxt} />
//                 </Col>
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="schoolPhoneNum" label="School PHONE NO. " onChange={onChangeTxt} />
//                 </Col>
//                 {/* <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="schoolAbbravation" label="School Abbrevation" onChange={onChangeTxt} />
//                 </Col> */}
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="adrs_l1" label="Address Line 1" onChange={onChangeTxt} />
//                 </Col>
//                 <Col xs={12} className="mt-4">
//                   <PrimaryTextBox name="adrs_l2" label="Address Line 2" onChange={onChangeTxt} />
//                 </Col>
//                 <Col xs={12} className="mt-4">
//                   <Row>
//                     <Col xs={4}>
//                       <PrimaryTextBox name="adrs_city" label="City" onChange={onChangeTxt} />
//                     </Col>
//                     <Col xs={4}>
//                       <PrimaryTextBox name="adrs_state" label="State" onChange={onChangeTxt} />
//                     </Col>
//                     <Col xs={4}>
//                       <PrimaryTextBox name="adrs_pin_code" label="Pin Code" onChange={onChangeTxt} />
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>
//             </div>
//           </div>
//         </Col>
//       </Row>

//       <Row>
//         <Col xs={6} className='m-auto text-center mt-2 pt-2'>
//           <button className='btn-latest' style={{ minHeight: "35px", width: "150px" }} onClick={(e) => {
//             e.preventDefault();
//             onSubmit()
//             // localStorage.setItem("_accountId", slctSchool.id)
//             // addAccountId(slctSchool.id)
//             // navigate("/schoolDashboard/profile", {
//             //   state: {
//             //     schoolData: slctSchool
//             //   }
//             // })

//           }}>Create</button>
//         </Col>
//         <Col xs={12}>
//           <div className='text-center'>
//             <h6 style={{ color: "red" }}>{errMsg}</h6>
//           </div>
//         </Col>
//       </Row>
//       <br />
//       <br />
//       <br />
//     </div>
//   )
// }

import React, { useState } from 'react'
import NavBar from '../../navBar';
import { Col, Row } from 'react-bootstrap';
import PrimaryTextBox from '../../CmnCompnts/TextBox';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';

export default function RegisterSchoolAdmin() {
  //28 states and 8 union territories of India
  const statesOfIndia = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry"];
  const [errMsg, setErrMsg] = useState("")
  const [userEmailError, setUserEmailError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [schoolNameError, setSchoolNameError] = useState("")
  const [udiseCodeError, setUdiseCodeError] = useState("")
  const [schoolabbreviationError, setSchoolAbbreviationError] = useState("")
  const [schoolCodeError, setSchoolCodeError] = useState("")
  const [schoolPhnNumError, setSchoolPhnNumError] = useState("")
  const [addressLine1Error, setAddressLine1Error] = useState("")
  const [addressLine2Error, setAddressLine2Error] = useState("")
  const [cityError, setCityError] = useState("")
  const [stateError, setStateError] = useState("")
  const [pinCodeError, setPinCodeError] = useState("")
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState(statesOfIndia);
  const [inputField, setInputField] = useState({
    userEmail: "",
    userName: "",
    schoolName: "",
    schoolCode: "",
    schoolAbbreviation: "",
    schoolPhoneNum: "",
    adrs_l1: "",
    adrs_l2: "",
    adrs_city: "",
    adrs_state: "",
    selectedState: "",
    adrs_pin_code: "",
  });

  const onChangeTxt = (e) => {
    const { name, value } = e.target;
    setInputField((prevInputField) => ({
      ...prevInputField,
      [name]: value,
    }));
    setUserEmailError("");
    setSchoolCodeError("");
    setAddressLine1Error("");
    setSchoolPhnNumError("")
    setSchoolAbbreviationError("")
    setSchoolNameError("")
    setPinCodeError("")
    setUdiseCodeError("")
    setAddressLine1Error("")
    setAddressLine2Error("");
    setCityError("")
    setStateError("")
    if (e.target.name === "adrs_state") {
      filterStates(value);
    }
  };

  const filterStates = (value) => {
    const filtered = statesOfIndia.filter((state) =>
      state.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStates(filtered);
  };

  const handleStateSelection = (state) => {
    console.log("Ssssssssssssssss", state);
    setInputField({
      ...inputField,
      adrs_state: state,
    });
    setSelectedState(state);
    setFilteredStates([]); // Clear the filtered states
  };

  const navigate = useNavigate()

  const onSubmit = () => {
    console.log("inputField.adrs_state", inputField.adrs_state);
    let generateSchoolCode = `${inputField.adrs_state.slice(0, 3) + "_" + inputField.adrs_city.slice(0, 3) + "_" + inputField.schoolName.slice(0, 3)}`
    let payload = {
      userEmail: inputField.userEmail,
      userName: inputField.userName,
      schoolName: inputField.schoolName,
      schoolCode: inputField.schoolCode,
      schoolPhoneNum: inputField.schoolPhoneNum,
      address: inputField.adrs_l1 + " " + inputField.adrs_l2 + " " + inputField.adrs_city + " " + inputField.adrs_pin_code,
      schoolCodeForUsers: inputField.schoolCode,
      udiseCode: inputField.udiseCode,
      schoolAbbreviation: inputField.schoolAbbreviation,
      schoolPrefix: "",
    }
    // console.log("Generate school code", generateSchoolCode);

    const errors = [];

    // Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!inputField.userEmail.match(emailRegex)) {
      setUserEmailError('Invalid email format');
      errors.push('Invalid email format');
    } else {
      setUserEmailError('');
    }

    // School Name Validation
    const nameRegex = /^[A-Za-z ]+$/;
    if (!inputField.schoolName.match(nameRegex)) {
      setSchoolNameError("Invalid school name format. Only alphabets are allowed.");
      errors.push("Invalid school name format. Only alphabets are allowed.");
    } else {
      setSchoolNameError("");
    }

    // Primary contact name error
    const userNameRegex = /^[A-Za-z ]+$/;
    if (!inputField.userName.match(userNameRegex)) {
      setUserNameError("Invalid user name format. Only alphabets are allowed.");
      errors.push("Invalid user name format. Only alphabets are allowed.");
    } else {
      setUserNameError("");
    }

    //UDISE Code Validation
    const numberRegex = /^[0-9]+$/;
    if (!inputField.udiseCode || !inputField.udiseCode.match(numberRegex)) {
      setUdiseCodeError("Invalid UDISE Code. Only numbers are allowed");
      errors.push("Invalid UDISE Code. Only numbers are allowed");
    } else {
      setUdiseCodeError("");
    }

    //School Abbreviation Validation
    const schoolabbvRegex = /^[A-Za-z ]+$/;
    if (!inputField.schoolAbbreviation.match(schoolabbvRegex)) {
      setSchoolAbbreviationError("Invalid school abbreviation format. Only alphabets are allowed.");
      errors.push("Invalid school abbreviation format. Only alphabets are allowed.");
    } else {
      setSchoolAbbreviationError("");
    }

    //School Code Validation
    if (inputField.schoolCode.length === 0) {
      setSchoolCodeError("Please Enter School Code");
      errors.push("Please Enter School Code");
    } else {
      setSchoolCodeError("");
    }

    // School Phone Number Validation
    const schPhnNumRegex = /^\d{10}$/;
    const schoolPhoneNum = inputField.schoolPhoneNum;
    if (!schoolPhoneNum.match(schPhnNumRegex)) {
      setSchoolPhnNumError("Invalid School Phone Number. Only numbers are allowed, and it must have exactly 10 digits.");
      errors.push("Invalid School Phone Number. Only numbers are allowed, and it must have exactly 10 digits.");
    } else {
      setSchoolPhnNumError("");
    }

    //Address Line1 Validation
    if (inputField.adrs_l1.length === 0) {
      setAddressLine1Error("Please enter your Address");
      errors.push("Please enter your Address");
    } else {
      setAddressLine1Error("");
    }

    //Address Line2 Validation
    if (inputField.adrs_l2.length === 0) {
      setAddressLine2Error("Please enter your Address");
      errors.push("Please enter your Address");
    } else {
      setAddressLine2Error("");
    }

    //City Validation
    const cityNameRegex = /^[A-Za-z ]+$/;
    if (!inputField.adrs_city.match(cityNameRegex)) {
      setCityError("Invalid City name format. Only alphabets are allowed.");
      errors.push("Invalid City name format. Only alphabets are allowed.");
    } else {
      setCityError("");
    }

    // State Validation
    if (selectedState.length === 0) {
      setStateError("Please enter State")
    }

    //Pincode Validation, only 6 numbers allowed
    const pincodeRegex = /^[1-9][0-9]{5}$/; // Regex for pincode format validation
    if (!inputField.adrs_pin_code.match(pincodeRegex)) {
      setPinCodeError("Invalid Pincode format. Only numbers are allowed");
      errors.push("Invalid Pincode format. Only numbers are allowed");
    } else {
      setPinCodeError("");
    }

    // Check if there are any errors
    if (errors.length > 0) {
      setErrorMessage("Please fix the errors in the form");
      console.log(errors);
      return;
    } else {
      setErrorMessage("");
    }

    axios
      .post("/lmAccounts/addSchoolByAdmin", payload)
      .then((res) => {
        console.log("School And account Created: ", res.data);
        localStorage.setItem("_accountId", res.data.accountData._id)
        if (res.data.err === "") {
          setErrMsg("School Created!")
        }
        setTimeout(() => {
          navigate('/schoolDashboard/profile')
        }, 3000);
      })
      .catch((error) => {
        console.log(error)
        setErrMsg("Please Check Email/School Code May already exist")
        setTimeout(() => {
          setErrMsg("")
        }, 3000);
      });
  }

  return (
    <div>
      <Row>
        <Col xs={6}>
          <div></div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} className='m-auto mt-5'>
          <div className='mb-2 pb-2 text-center'>
            <h4><b>Add New School Account</b></h4>
            <div className='add-new-school-form mt-3'>
              <Row>
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="userEmail" label="PRIMARY ACCOUNT EMAIL" onChange={onChangeTxt} />
                  {userEmailError && <h6 style={{ color: "red", fontWeight: "bold" }}>{userEmailError}</h6>}
                </Col>
                <Col xs={12} className="mt-4" required>
                  <PrimaryTextBox name="userName" label="PRIMARY CONTACT NAME" onChange={onChangeTxt} />
                  {userNameError && <h6 style={{ color: "red", fontWeight: "bold" }}>{userNameError}</h6>}
                </Col>
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="schoolName" label="SCHOOL NAME" onChange={onChangeTxt} />
                  {schoolNameError && <h6 style={{ color: "red", fontWeight: "bold" }}>{schoolNameError}</h6>}
                </Col>
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="udiseCode" label="UDISE CODE" onChange={onChangeTxt} />
                  {udiseCodeError && <h6 style={{ color: "red", fontWeight: "bold" }}>{udiseCodeError}</h6>}
                </Col>
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="schoolAbbreviation" label="SCHOOL ABBREVIATION" onChange={onChangeTxt} />
                  {schoolabbreviationError && <h6 style={{ color: "red", fontWeight: "bold" }}>{schoolabbreviationError}</h6>}
                </Col>
                {/* <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="schoolPrefix" label="School Prefix" onChange={onChangeTxt} />
                </Col> */}
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="schoolCode" label="SCHOOL CODE (UNIQUE)" onChange={onChangeTxt} />
                  {schoolCodeError && <h6 style={{ color: "red", fontWeight: "bold" }}>{schoolCodeError}</h6>}
                </Col>
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="schoolPhoneNum" label="SCHOOL PHONE NUMBER " onChange={onChangeTxt} />
                  {schoolPhnNumError && <h6 style={{ color: "red", fontWeight: "bold" }}>{schoolPhnNumError}</h6>}
                </Col>
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="adrs_l1" label="ADDRESS LINE 1" onChange={onChangeTxt} />
                  {addressLine1Error && <h6 style={{ color: "red", fontWeight: "bold" }}>{addressLine1Error}</h6>}
                </Col>
                <Col xs={12} className="mt-4">
                  <PrimaryTextBox name="adrs_l2" label="ADDRESS LINE 2" onChange={onChangeTxt} />
                  {addressLine2Error && <h6 style={{ color: "red", fontWeight: "bold" }}>{addressLine2Error}</h6>}
                </Col>
                <Col xs={12} className="mt-4">
                  <Row>
                    <Col xs={4}>
                      <PrimaryTextBox name="adrs_city" label="CITY" onChange={onChangeTxt} />
                      {cityError && <h6 style={{ color: "red", fontWeight: "bold" }}>{cityError}</h6>}
                    </Col>
                    <Col xs={4}>
                      <div className="position-relative">
                        <TextField size="medium" name={"adrs_state"} id="outlined-basic" label={"STATE"} value={inputField.adrs_state} variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} required />
                        {
                          (inputField.adrs_state.length > 2 && filteredStates.length > 0) ? <div className="w-100 display-panel" style={{ zIndex: "10", backgroundColor: "white", padding: "15px", position: "absolute", left: "0px", maxWidth: "200px", boxShadow: "0px 10px 10px #D0D0D0" }}>
                            {filteredStates.map((state) => (
                              <button key={state} style={{ cursor: "pointer", backgroundColor: "transparent", width: "100%", border: "none" }} onClick={() => handleStateSelection(state)}>
                                {state}
                              </button>
                            ))}
                          </div> : ""
                        }
                      </div>
                      {stateError && <h6 style={{ color: "red", fontWeight: "bold" }}>{stateError}</h6>}
                    </Col>
                    <Col xs={4}>
                      <PrimaryTextBox name="adrs_pin_code" label="PINCODE" onChange={onChangeTxt} />
                      {pinCodeError && <h6 style={{ color: "red", fontWeight: "bold" }}>{pinCodeError}</h6>}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} className='m-auto text-center mt-2 pt-2'>
          <button className='btn-latest' style={{ minHeight: "35px", width: "150px" }} onClick={(e) => {
            e.preventDefault();
            onSubmit()
            // localStorage.setItem("_accountId", slctSchool.id)
            // addAccountId(slctSchool.id)
            // navigate("/schoolDashboard/profile", {
            //   state: {
            //     schoolData: slctSchool
            //   }
            // })

          }}>Create</button>
        </Col>
        <Col xs={12}>
          <div className='text-center'>
            <h6 style={{ color: "red", fontWeight: "bold" }}>{errMsg}</h6>
            {errorMessage && <h6 style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</h6>}
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </div>
  )
}
