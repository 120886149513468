import { useState, useEffect } from 'react';
import React from 'react';
import axios from 'axios';
import { Accordion, Modal, Tab, Table } from 'react-bootstrap';
import detailsIcon from "../../Assets/details-icon.png"
import './ReportsData.css'
import useGlobalData from '../../../Zustand/GlobalData';
import moment from 'moment'

const SessionReport = (props) => {

    const { InitialUpdateAccountsData, accountId, usersData, schoolData, updateUsersData } = useGlobalData();
    const [reports, setReports] = useState([]);
    const [details, setDetails] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDt2, setSelectedDt2] = useState(null);
    const [openAccordion, setOpenAccordion] = useState();


    useEffect(() => {
        let accountId = usersData.length > 0 ? usersData[0]['_lmAccountId'] : ''
        axios.get(`/alexa/user/report/${accountId}`)
            .then((res) => {
                console.log("res.data", res.data);
                setReports(res.data.data);
                setDetails(res.data.data);
                let reportscsvdata = []
                res.data.data.flatMap((report) => report.gradeCourses).map((dt2, index) => {
                    dt2.sessionDetails.map((session, index) =>{
                        let start = moment(session.startDate); // some random moment in time (in ms)
                        let end = moment(session.endDate); // some random moment after start (in ms)
                        let diff = end.diff(start, 'milliseconds');
                        let timeinhhmmss = moment.utc(diff).format('HH:mm:ss');
                        let tempObj = {
                            RegNumber: dt2.userDetails[0]['regNum'],
                            Name: dt2.userDetails[0]['userName'],
                            Email: dt2.userDetails[0]['userEmail'],
                            Course: dt2.course,
                            Grade: dt2.grade,
                            Role: dt2.userRole,
                            "Start Date": session.startDate,
                            "End Date": session.endDate,
                            "timeSpent in hh:mm:ss": timeinhhmmss,
                            "timeSpent in sec" : diff,
                            "Sessions Number": index+1
                        }
                        reportscsvdata.push(tempObj)
                    })
                   
                })
                props.setReports(reportscsvdata);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, []);


    const handleModalOpen = (dt2) => {
        setSelectedDt2(dt2);
        setShowModal(true);
    };


    const handleModalClose = () => {
        setSelectedDt2(null);
        setShowModal(false);
    };


    const toggleAccordion = (index) => {
        setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
        // setOpenAccordion(index)
    };

    const getDate = (arg) => {
        const dateString = arg
        const dateObject = new Date(dateString);

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();

        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        return formattedDate;
    }

    const getTime = (arg1, arg2) => {
        const startTime = new Date(arg1);
        const endTime = new Date(arg2);

        const timeDifference = endTime - startTime;

        const hours = Math.floor(timeDifference / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((timeDifference / 1000) % 60).toString().padStart(2, '0');

        const formattedTimeDuration = `${hours}:${minutes}:${seconds}`;
        return formattedTimeDuration;
    }

    const getTotalTimeDuration = (totalMilliseconds) => {
        const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((totalMilliseconds / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((totalMilliseconds / 1000) % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    const convertToSeconds = (time) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const addTime = (totalTimeSpentDuration, newTime) => {
        console.log("sssssssss", totalTimeSpentDuration, newTime);
        let seconds = convertToSeconds(newTime);

        let temp = parseInt(totalTimeSpentDuration) + parseInt(seconds);

        return temp;
    }

    const convertSecondsToTimeFormat = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };


    return (
        <div>
            <Table className='mt-3' bordered responsive hover style={{ backgroundColor: "white" }}>
                <thead>
                    <tr>
                        <th>RegNumber</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Course</th>
                        <th>Grade</th>
                        <th>Role</th>
                        <th>Sesion Start Date</th>
                        <th>Session End Date</th>
                        <th>Time Spent</th>
                        <th>Time Spent in ms</th>
                        <th>Session Number</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.flatMap((report) => report.gradeCourses).map((dt2, index) => {
                        let totalTimeSpentDuration = 0;
                        let overallTimeSpent = 0;

                       return  dt2.sessionDetails.map((session, index) => {
                        let start = moment(session.startDate); // some random moment in time (in ms)
                        let end = moment(session.endDate); // some random moment after start (in ms)
                        let diff = end.diff(start, 'milliseconds');
                        let diffSeconds = end.diff(start, 'seconds');
                        let timeinhhmmss = moment.utc(diff).format('HH:mm:ss');
                            return (
                                <React.Fragment key={dt2._id}>
                                    <tr onClick={() => toggleAccordion(index)}>
                                        <td>{dt2.userDetails[0]['regNum']}</td>
                                        <td>{dt2.userDetails[0]['userName']}</td>
                                        <td>{dt2.userDetails[0]['userEmail']}</td>
                                        <td>{dt2.course}</td>
                                        <td>{dt2.grade}</td>
                                        <td>{dt2.userRole}</td>
                                        <td>{moment(session.startDate).format('LL')} {moment(session.startDate).format('LTS')}</td>
                                        <td>{moment(session.endDate).format('LL')} {moment(session.endDate).format('LTS')}</td>
                                        <td>{timeinhhmmss}</td>
                                        <td>{diffSeconds}</td>
                                        <td>{index+1}</td>
                                    </tr>
                                </React.Fragment>
                            );
                        })
                      
                    })}
                </tbody>
            </Table>
        </div>

    );
};


export default SessionReport;






