import React from "react";
import { Switch } from "@mui/material";
import { activeStyle, hoverStyle, basic } from '../../themes/basic';
const SwitchStyle = (props) => {
  return (
    <Switch
      sx={{
        marginRight: 1,
        width: 34,
        height: 16,
        padding: 0,

        "& .MuiSwitch-thumb": {
          width: 8,
          height: 8,
          boxShadow: 0,
        },
        "& .MuiSwitch-track": {
          backgroundColor: "transparent",
          border: "2px solid black",
          opacity: 1,
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: basic.greenColor,
          border: "0px solid #f3f3f3",
          opacity: 1,
        },

        "& .MuiSwitch-switchBase": {
          color: "black",

          "&.Mui-checked": {
            // thumb - checked
            color: "white",
          },
        },
      }}
      {...props}
    />
    // <Switch {...props} />
  );
};

export default SwitchStyle;
